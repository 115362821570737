import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { rhythm, scale } from "../utils/typography";
import { SocialIcons } from "./SocialIcons";
import Image from "gatsby-image";

export function Header() {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          subtitle
          social {
            twitter
            github
            linkedin
          }
        }
      }
      avatar: file(absolutePath: { regex: "/profile-pic.png/" }) {
        childImageSharp {
          fixed(width: 100, height: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);

  return (
    <header
      style={{
        display: 'flex',
        alignItems: 'center',
        maxWidth: rhythm(25),
        marginBottom: rhythm(2)
      }}
    >
      <div style={{
        marginRight: rhythm(1)
      }}>
          <Image
            fixed={data.avatar.childImageSharp.fixed}
            style={{
              marginBottom: 0,
              minWidth: 50,
              borderRadius: `100%`
            }}
            imgStyle={{
              borderRadius: `50%`
            }}
          />
      </div>
      <div>
        <div style={{ display: "flex", alignItems: "baseline" }}>
          <h1
            style={{
              marginBottom: 0,
              marginRight: rhythm(1 / 2),
              ...scale(1 / 2)
            }}
          >
            {data.site.siteMetadata.title}
          </h1>
          <SocialIcons {...data.site.siteMetadata.social} />
        </div>



      <p style={{ marginBottom: rhythm(1 / 2) }}>
        {data.site.siteMetadata.subtitle}
      </p>
      </div>
    </header>
  );
}
