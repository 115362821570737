export const externalPost = [
  // {
  //   url: ",
  //   title: "",
  //   desc: "",
  //   date: "",
  //   imgUrl: "",
  //   external: true
  // }
];
